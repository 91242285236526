<script lang="ts" setup>
import { Menu } from '~/api/drupal/useMenu'
const props = defineProps<{
  menu: Menu
}>()
const items = computed(() =>
  props.menu.map((item) => {
    return {
      id: item.id,
      items: [...(item.items || [])].map((item) => {
        return {
          id: item.id,
          link: {
            text: item.title,
            url: item.url,
            target: item.target,
          },
        }
      }),
    }
  }),
)
</script>
<template>
  <ul class="columns cul">
    <li v-for="item in items" :key="item.id" class="menu-column">
      <LinkList :items="item.items" />
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.columns {
  display: grid;
  grid-template-columns: 100%;
  gap: 0 var(--gap);
  @include breakpoint(tl) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
